<template>
  <router-view default="{name: 'login'}"> </router-view>
  <NlTawk></NlTawk>

  <LoadingBar v-if="getLoadingBarStatus" :key="getLoadingBarStatus" />
</template>

<script>
require("@/assets/css/style.less");
import NlTawk from "@/components/NlTawk/index.vue";


export default {
  name: "app",
  components: {
    NlTawk,
   
  },


  computed: {
    getLoadingBarStatus() {
      return this.$store.getters["getSpinnerStatus"];
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  margin: 0;
  overflow-x: hidden;
  inset: 0;
  box-sizing: border-box;
  background: #dcd7ddee !important;
  
}
</style>
