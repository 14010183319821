import uesLms from '../services';
import tools from '../tools';
import config from '../config';
import store from '../store';

export default {
  async login(username, password) {
    try {
      const res = await uesLms().post('/authenticate', {
        email: username,
        password,
        api_key: config.apiKey,
      });

   

      if (res.message === 'Request failed with status code 401') {
        return {
          success: false,
        };
      }
      await tools.cookie.set(config.global_key.tokenName, res.data.token);
      return {
        success: true,
        data: res,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async loginSso(userData) {
    try {
      let user = null;
    
      if (userData.roleType.toLowerCase() === 'student') {
        user = await uesLms().post('/platformUserCreate', {
          school_id: userData.school_id,
          campus_name: userData.campus_name,
          first_name: userData.first_name,
          last_name: userData.last_name,
          username: userData.username,
          password: userData.password,
          api_key: config.apiKey,
          role: userData.roleType,
          class: userData.class,
          branch: userData.branch,
        });
      } else if (userData.roleType.toLowerCase() === 'teacher') {
        user = await uesLms().post('/platformUserCreate', {
          school_id: userData.school_id,
          campus_name: userData.campus_name,
          first_name: userData.first_name,
          last_name: userData.last_name,
          username: userData.username,
          password: userData.password,
          api_key: config.apiKey,
          role: userData.roleType,
          classes: userData.classes,
        });
      }
      if (user.data.status_code !== 422) {
        const res = await uesLms().post('/authenticate', {
          email: userData.username,
          password: userData.password,
          api_key: config.apiKey,
        });
        if (res.message === 'Request failed with status code 401') {
          return {
            success: false,
          };
        }
        await tools.cookie.set(config.global_key.tokenName, res.data.token);
        return {
          success: true,
          data: res.data,
        };
      }
      return {
        success: true,
        data: user.data.message,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async refreshToken() {
    try {
     
      let {username,password} = store.getters.getUserInfo;
   
      const res = await uesLms().post('/authenticate', {
        email: username,
        password,
        api_key: config.apiKey,
      });
      await tools.cookie.set(config.global_key.tokenName, res.data.token);
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async checkSchool() {
    try {
      const res = await uesLms().post('/organization/checkNLSchool');
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async loginLog(agent, ip) {
    try {
      const res = await uesLms().post('/user/loginLog', {
        api_key: config.apiKey,
        user_agent: agent,
        ip_address: ip,
      });
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
};
