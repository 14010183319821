import authService from '@/services/auth';

export default {
  state: {
    username: '',
    password: '',
    first: false,
  },

  mutations: {
    setUserInformation(state, payload) {
     
      state.username = payload.username;
      state.password = payload.password;
    },
    setFirst(state, payload) {
      state.first = payload;
    },
  },
  actions: {
    login(context, {username,password}) {
      return new Promise((resolve, reject) => {
        authService
          .login(username, password)
          .then(async (res) => {
           
            if (res.success) {
              context.commit('setUserInformation', {
                 username,
                 password,
              });
              context.dispatch('getUserDetails');
              resolve(res);
            } else {
              resolve(res);
            }
          })
          .catch(err => reject(err));
      });
    },
    loginSso(context, payload) {
      return new Promise((resolve, reject) => {
        authService
          .loginSso(payload)
          .then(async (res) => {
            if (res.success) {
              context.commit('setUserInformation', {
                username: payload.username,
                password: payload.password,
              });
              context.dispatch('getUserDetails');
              resolve(res);
            } else {
              resolve(res);
            }
          })
          .catch(err => reject(err));
      });
    },
  },

  getters: {
    getUserInfo(state){
      
      return {username:state.username,password:state.password}
    }
  },
};
