<template>
  <div
    class="status-container"
    :style="[
      status
        ? `background:${statusBgColor.succes[type]};`
        : `background:${statusBgColor.failed.bgColor};`,
    ]"
  >
    <p class="status-message">
      <i v-if="status" class="fa-regular fa-circle-check"></i>
      <i class="fa-regular fa-circle-xmark" v-else></i>

      {{ statusText }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      required: true,
      default: "",
    },

    status: {
      required: true,
      default: true,
    },
  },
  created() {
    this.setStatusText();
  },

  methods: {
    setStatusText() {
      setTimeout(() => {
        this.$emit("setProcessStatus", null);
        if (this.status) this.$router.push("/my-educlass-teacher/assigments");
      }, 2000);

      if (this.status) {
        this.statusText = this.type.toUpperCase() + "  SUCCESSED";
      } else {
        this.statusText = this.type.toUpperCase() + "  FAILED !";
      }
    },
  },
  data() {
    return {
      statusBgColor: {
        succes: {
          create: "green",
          edit: "rgb(240, 130, 4)",
          delete: "rgb(14, 70, 107)",
        },

        failed: {
          bgColor: "rgb(148, 12, 12)",
        },
      },

      statusText: "",
    };
  },
};
</script>
<style scoped lang="scss">
.status-container {
  position: fixed;
  top: 25%;
  left: 25%;
  color: white;
  height: 45vh;
  width: 50%;
  border-radius: 12px;
  box-shadow: 4px 4px 4px rgba(88, 88, 179, 0.445);
  display: flex;
  justify-content: center;
  justify-self: center;
  align-items: center;
  font-size: 1.12rem;
  font-weight: 600px;
  z-index: 9;

  & i {
    font-size: 2rem;
    margin-right: 2px;
    margin-top: 15px;
  }

  @media (max-width: 900px) {
    height: 35vh;
    width: 60%;

    top: 35%;
    left: 20%;
  }

  @media (max-width: 500px) {
    height: 25vh;
    width: 80%;

    top: 35%;
    left: 10%;
  }
}
</style>