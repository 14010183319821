
import services from '@/services/content'
export default {

    namespaced: true,
    state:{
        assignmentAll : [],
        currentAssignments: [],
        expiredAssignments: [],
        selectedAssignmentCard:{}

    },

    mutations:{
        setAssignmentAll(state,assignments){
            state.assignmentAll = assignments;
        },
        setExpiredAssignment(state,assigments){
            state.expiredAssignments = assigments;
        },

        setCurrentAssignment(state,assigments){
            state.currentAssignments = assigments;
        },
        setSelectedAssignmentCard(state,assignment){
            state.selectedAssignmentCard = assignment;
        }



    },

    actions:{
        async setStudentAssignments({commit}){
            const { data:{data:assignments} } = await services.getAssignments('1', 'id');
            commit('setAssignmentAll',assignments);
            const expiredAssignments=[];
            const currentAssignments=[];
            let currentDate = new Date();
            assignments.forEach(item => {
                let assignmentExpiredDate = new Date(item.end_date);
                if(currentDate.getTime() > assignmentExpiredDate.getTime()){
                    expiredAssignments.push(item);
                }
                else {
                    currentAssignments.push(item);
                }

            });

            commit('setExpiredAssignment',expiredAssignments);
            commit('setCurrentAssignment',currentAssignments);

        }
    },

    getters:{
        getAssignmentAll(state){
           return state.assignmentAll ;
        },
        getExpiredAssignment(state){
            return  state.expiredAssignments;
        },

        getCurrentAssignment(state){
            return   state.currentAssignments;
        },
        getSelectedAssignmentCard(state){
            return state.selectedAssignmentCard;
        }
    }
}