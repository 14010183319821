<template>
  <div>
    <div
      class="container"
      v-if="isVisibleToUser && isShownType == 'platform-page'"
    >
      <div class="rlp-logo-container" @click="go_RLP">
        <img
          @click="go_RLP"
          :src="require('@/assets/logoRLP.svg')"
          alt="RLP.logo"
        />
      </div>

      <div
        class="RLP-code-container"
        v-if="
          getRLPCodeVisibleStatus
        "
      >
        <div class="rlp-steps-container">
          <p class="rlp-steps-title">
            Richmond Learning Platform Aktivasyon Adımları:
          </p>
          <ul class="steps-rlp-code">
            <li>
              Öncelikle aşağıdaki "Kodu Kopyala" butonuna basarak kitap kodunuzu
              kopyalayınız.
            </li>
            <li>
              Yukarıda gördüğünüz Richmond Learning Platform simgesine
              tıklayınız ve siteye geçiş yapınız.
            </li>
            <li>
              Açılan sayfa, sizden kod girmenizi isteyecektir. Kopyaladığınız
              kodu "Please enter a code" alanına yapıştırınız.
            </li>

            <li>
              Kodu yapıştırdıktan sonra sağ alt bölümde görünen "Add Product"
              butonuna tıklayarak kodu aktifleştiriniz.
            </li>
            <li>
              <span style="color: #cc0909"> UYARI :</span> Yukarıdaki adımlar
              tamamlandıktan sonra bu işlemi bir daha yapmanıza gerek yoktur.
              Richmond Learning Platform simgesine tıklayarak sistemi kullanmaya
              devam edebilirsiniz.
            </li>
          </ul>
        </div>

        <div class="code-navigate-container">
          <span class="rlp-code"> {{ rlpCode }} </span>
          <div
            @click="copyText(rlpCode)"
            class="copy-short-cut-box"
          >
            <div v-if="isCopied" class="copied">COPIED !</div>
            <i class="fa-solid fa-copy"></i>
            <div class="copy-text">Kodu Kopyala</div>
          </div>
        </div>
      </div>
    </div>

    <router-link
      v-if="isVisibleToUser && isShownType == 'header-page'"
      @click="$emit('setToggle')"
      class="platforms"
      tag="p"
      :to="`/platforms`"
    >
      <img
        src="@/assets/student-page-assets/assigment_icon.svg"
        class="platforms-icon"
        alt="platforms"
      />

      <span class="platforms-text">Platforms</span>
    </router-link>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import { mapGetters } from "vuex";
export default {
  props: {
    isShownType: {
      type: String,

      default: "platform-page",
    },
  },
  data() {
    return {
      userData: {},
      rlpInstituteCode:null,
      rlpCode:null,
      isCopied: false,
   

  
    };
  },

  computed: {

    getRLPCodeVisibleStatus() {
        const isRLPVisible =  this.userData.role_type == 'student' && (this.rlpCode && this.rlpCode !== "###");
        return isRLPVisible; 
    },
    isVisibleToUser() {
      if (Object.keys(this.userData).length) {
        if( this.rlpInstituteCode){
          return true;
        }
        else return false;

      }
    },

    ...mapGetters(["userDetails"]),
  },

  created() {
    this.setUserDataInfo();
  },

  methods: {
    copyText(text) {
      navigator.clipboard.writeText(text);
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 2000);
    },

    setUserDataInfo() {
      if (Object.keys(this.userData).length == 0 && this.userDetails) {
        let { user_id, first_name, last_name, role_type, organization, email,rlp_code = "" } =
          this.userDetails;
        this.rlpCode =  rlp_code;
        
        this.userData.user_id = user_id;
        this.userData.first_name = first_name;
        this.userData.last_name = last_name;
        this.userData.role_type = role_type;
        this.userData.username = email;
        
        if (organization.length) {
          organization.forEach((userSchoolInfo) => {
            if (userSchoolInfo.type == "main") {
              this.userData.school_id = userSchoolInfo.id;
            }
            if (userSchoolInfo.type == "campus") {
              this.rlpInstituteCode = userSchoolInfo?.rlp_institute_code || "";

              this.userData.campus_id = userSchoolInfo.id;
              this.userData.campus_name = userSchoolInfo.name;
            }

            if (userSchoolInfo.type == "grade") {
              this.userData.userClassId = userSchoolInfo.id;
              let grade = userSchoolInfo.name.split("-");
              this.userData.class = [
              {
            grade: grade[0],
            branch: grade[1]
              }   ] 
                                  
            }
          });
        }
      } else {
        this.userData = {};
      }
    },
    go_RLP() {
      if (this.userData.role_type == "teacher") {
        window.open("https://richmondlp.com/login", "_blank");

        // location.href = "https://richmondlp.com/login";
      } else {
        const secretKey =
          "VswFYGzh2k45N7Q8R9@TBuCVexFY.G2J3K4N6p7Q%9SATB-VdWeXFZHJ3m5N"; // Değiştirmeyiniz
        
        let textJson = JSON.stringify(this.userData);
        let uriText = decodeURIComponent(textJson);
        let ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();

        // location.href = "http://localhost:8080/#/rlp/?sso=" + btoa(ciphertext);
        // location.href =
        //   "https://service.ueslms.com/#/rlp/?sso=" + btoa(ciphertext);

        window.open(
          "https://service.ueslms.com/#/rlp/?sso=" + btoa(ciphertext),
          "_blank"
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
  width: 100%;
  min-height: 240px;
  height: auto;
  background: rgba(255, 255, 255, 0.877);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.RLP-code-container {
  width: 55%;
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;

  background: #f2994a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f2c94c,
    #f2994a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f2c94c,
    #f2994a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  padding: 10px 20px;
  margin: auto;
  border-radius: 8px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 0.9rem;
  line-height: 20px;

  color: rgb(19, 9, 26);
  font-weight: 600;
  @media (min-width: 1700px) {
    width: 30%;
  }
  @media (min-width: 1400px) {
    width: 53%;
  }

  @media (max-width: 1000px) {
    width: 60%;
  }

  @media (max-width: 800px) {
    width: 70%;
  }

  @media (max-width: 600px) {
    width: 80%;
  }

  @media (max-width: 500px) {
    font-size: 0.8rem;
  }

  @media (max-width: 400px) {
    width: 86%;
    font-size: 0.7rem;
  }

  @media (max-width: 350px) {
    font-size: 0.6rem;
  }

  .copied {
    height: 25px;
    width: max-content;

    padding: 2px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(248, 248, 248, 0.959);
    color: #0f0f30;
    position: absolute;
    border-radius: 4px;
    transition: 0.5s all ease-in-out;
    right: 20%;
    top: -32px;

    @media (max-width: 1500px) {
      left: -1%;
    }
  }
  .rlp-steps-container {
    display: flex;
    flex-flow: column;

    justify-content: center;
    flex-basis: 80%;

    .rlp-steps-title {
      font-size: 1rem;
      font-weight: bolder;
    }
    .steps-rlp-code {
      list-style-type: decimal;
      padding-left: 2%;

      & li {
        margin-bottom: 12px;
      }

      & :last-child {
        list-style-type: none;
        font-weight: 800;
      }
    }

    @media (max-width: 800px) {
      flex-basis: 84%;
    }

    @media (max-width: 450px) {
      flex-basis: 96%;
    }
  }

  .code-navigate-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    .rlp-code {
      color: #712581;
      width: max-content;
      margin-right: 10px;
      font-size: 1.3rem;
    }

    .copy-short-cut-box {
      color: white;
      width: max-content;
      position: relative;
      height: auto;
      background: rgba(0, 0, 0, 0.726);
      display: flex;
      flex-flow: column;
      gap: 5px;
      transform: scale(0.8);
      justify-content: center;
      align-items: center;
      font-size: 0.9rem;
      border-radius: 4px;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, 0.89);
      }

      & i {
        margin-top: 10px;
      }

      & .copy-text {
        margin-bottom: 10px;
      }
    }
  }
}
.rlp-logo-container {
  width: 200px;
  min-height: 160px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 30px;

  background: rgba(112, 111, 111, 0.288);
  bottom: 50px;
  right: 25px;

  &:hover {
    background: rgba(160, 156, 156, 0.795);
  }

  & img {
    width: 150px;
    height: 150px;
    object-fit: fill;
  }
}

.platforms {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 6px;
  color: #712581;
  width: 117px;
  height: 20px;

  text-decoration-line: none;
  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;

  @media (max-width: 930px) {
    background: rgb(39, 26, 77);
    display: flex;
    padding-left: 40%;
    width: 100vw !important;
    height: 75px;
    color: #eae5eb !important;
    border-bottom: 1px solid #eae5ebad !important;
    cursor: pointer;
    &:hover {
      background: rgb(81, 63, 134);
    }
  }

  .platforms-icon {
    width: 16px;
    height: 16px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .platforms-text {
    width: 95px;
    height: 20px;

    font-family: "Source Serif Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
}
</style>