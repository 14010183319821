<template>
  <div>
    <div class="container" v-if="isVisibleToUser && isShownType=='platform-page'" >
      <div class="meq-logo-container"  @click="go_MEQ">
      <img
      
        :src="require('@/assets/meQ-logo.png')"
        alt="MEQ.logo"
      />
    </div>

    </div>

<router-link
v-if="isVisibleToUser && isShownType =='header-page'"
    @click="$emit('setToggle')"
    class="platforms"
    tag="p"
    :to="`/platforms`"
  >
    <img
      src="@/assets/student-page-assets/assigment_icon.svg"
      class="platforms-icon"
      alt="platforms"
    />

    <span class="platforms-text">Platforms</span>
   
  </router-link>

  </div>

 
 
</template>

<script>
import CryptoJS from "crypto-js";
import { mapGetters } from 'vuex';
export default {
  props:{
    isShownType:{
      type:String,
      
      default:'platform-page'
    }
  },
  data() {
    return {
      userData: {},
      isCopied:false,
      allowedUsers: {
        schoolId: [11614],
        classes: [8],
       

      },

      campusAllowedIds:[48905],

 
    };
  },

  computed: {
    isVisibleToUser() {

      if (Object.keys(this.userData).length) {
          const userSchoolId = this.userData?.school_id || 0;
          const userCampusId = this.userData?.campus_id || 0;
        if (this.userData.role_type == "student") {
   
          const userClass = Number(this.userData?.class);
         


          if (
            this.allowedUsers.classes.includes(userClass) &&
            this.allowedUsers.schoolId.includes(userSchoolId) && (this.campusAllowedIds.includes(userCampusId))  
          ) {
            return true;
          }
          else return false;


        } else {
          if (this.allowedUsers.schoolId.includes(this.userData?.school_id) && (this.campusAllowedIds.includes(userCampusId)) ){
            return true;
          }
          else return false;
        }
      } else {
        return false;
      }
    },

    ...mapGetters(['userDetails'])
  },







 created(){
 

    this.setUserDataInfo()


  },

  methods: {

   

    setUserDataInfo(){
       
      
       
      if (  Object.keys(this.userData).length == 0 && (this.userDetails)) {
        let { user_id, first_name, last_name, role_type, organization, email } = this.userDetails ;


        //   JSON.parse(sessionStorage.getItem("userInfos"));
       
        this.userData.user_id = user_id;
        this.userData.first_name = first_name;
        this.userData.last_name = last_name;
        this.userData.role_type = role_type;
        this.userData.username = email;

        

        if (organization.length) {
          organization.forEach((userSchoolInfo) => {
            if (userSchoolInfo.type == "main") {
              this.userData.school_id = userSchoolInfo.id;
            }
            if (userSchoolInfo.type == "campus") {
              this.userData.campus_id = userSchoolInfo.id;
              this.userData.campus_name = userSchoolInfo.name;
            }

            if (userSchoolInfo.type == "grade") {
              this.userData.userClassId = userSchoolInfo.id;
              let grade = userSchoolInfo.name.split("-");
              this.userData.class = grade[0]; // class level
              this.userData.branch = grade[1]; // class branch;
            }
          });
        }
      }
      else {
        this.userData = {};
      }


    },
    go_MEQ() {
  
      
        const secretKey =
          "VswFYGzh2k45N7Q8R9@TBuCVexFY.G2J3K4N6p7Q%9SATB-VdWeXFZHJ3m5N"; // Değiştirmeyiniz
        let classes = [(this.userData.class + '-' + this.userData.branch)];
        this.userData['classes'] = classes;
        let textJson = JSON.stringify(this.userData);
        let uriText = decodeURIComponent(textJson);
        let ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();

        // location.href = "http://localhost:8080/#/rlp/?sso=" + btoa(ciphertext);
        // location.href =
        //   "https://service.ueslms.com/#/rlp/?sso=" + btoa(ciphertext);

          window.open("https://www.myeduquiz.com/?sso=" + btoa(ciphertext),"_blank");
        

      }
    },
  
};
</script>

<style scoped lang="scss">


.container {
    position: relative;
    width: 100%;
    min-height: 240px;
    height: auto;
    background: rgba(255, 255, 255, 0.877);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

  }
.meq-logo-container {
 
    width: 200px;
    min-height: 160px;
    height: auto;
    display: flex;
    justify-content: center;
   align-items: center;
   border-radius: 6px;
   cursor: pointer;
   margin-top: 30px;
    
    background:rgba(112, 111, 111, 0.288);
  bottom: 50px;
    right: 25px;

    &:hover {
      background:rgba(160, 156, 156, 0.795);
    }



    & img {
       
        width: 150px;
       height: 150px;
        object-fit: scale-down;


    }
}



.platforms {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 6px;
    color: #712581;
    width: 117px;
    height: 20px;

    text-decoration-line: none;
    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;

    @media (max-width: 930px) {
      background: rgb(39, 26, 77);
      display: flex;
      padding-left: 40%;
      width: 100vw !important;
      height: 75px;
      color: #eae5eb !important;
      border-bottom: 1px solid #eae5ebad !important;
      cursor: pointer;
      &:hover {
        background: rgb(81, 63, 134);
      }
    }

    .platforms-icon {
      width: 16px;
      height: 16px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .platforms-text {
      width: 95px;
      height: 20px;

      font-family: "Source Serif Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height */

      /* Inside auto layout */

      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }
</style>