import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import axios from '@/services'
import HeaderApp from "./components/main-components/HeaderApp.vue"
import FooterApp from "./components/main-components/FooterApp.vue" 
import DatePicker from '@vuepic/vue-datepicker';
import LoadingBar from '@/components/loadingBar/loading.vue'
import EmptyWarningMessage from'./components/main-components/EmptyWarningMessage.vue'
import StatusMessageBox from './components/main-components/StatusShowMessage.vue'
import '@vuepic/vue-datepicker/dist/main.css'


import {
    // create naive ui
    create,
    // component
    NButton,
    NSelect,
    NTreeSelect,
    NSpace,
    NForm,
    NInput,
    NTable,
    NDatePicker

  } from 'naive-ui'
  
  const naive = create({
    components: [NButton,NSelect,NTreeSelect,NSpace,NForm, NInput,NTable, NDatePicker]
  })
  
  const isLocal = window.location.hostname === 'localhost';

if (isLocal) {
  window['ga-diasble-UA-127427549-9'] = true;
}

const app = createApp(App);
app.config.globalProperties.$axios = axios();

app.use(router).use(naive).use(store);

app.component('DatePicker',DatePicker);
app.component('LoadingBar',LoadingBar);
app.component('StatusMessageBox',StatusMessageBox);
app.component("HeaderApp",HeaderApp).component('EmptyWarningMessage',EmptyWarningMessage ).component("FooterApp",FooterApp).mount('#app')
