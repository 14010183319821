<template>
    <div style="background: rgb(223, 219, 219); padding-top: 10px;padding-bottom: 10px;overflow: hidden;">

  
  <div class="header-container" :style="toggle ? responsiveStyle : {}">
    <img
      src="@/assets/student-page-assets/logo.svg"
      @click="$router.push(`/my-educlass-${userInfo.role.toLowerCase()}`)"
      :style="toggle ?  {'top':'5%'} : {'align-self':'center'} "
      class="logo"
      alt=""
    />

    <div class="navbar-item" :style="toggle ? { visibility: 'visible' } : {}">
      <router-link
        @click="toggle = false"
        tag="p"
        to="/my-educlass-teacher/resources"
        class="resources"
        v-if="userInfo.role.toLowerCase() != 'student' || isResourcesFull "
      >
        <img
          src="@/assets/student-page-assets/source_icon.svg"
          class="resources-icon"
          alt=""
        />
        <span class="resources-text"> Resources</span>
      </router-link>

      <router-link
      v-show="!($store.getters.getIsUserSchoolIdRestrict)"
        @click="toggle = false"
        class="library"
        tag="p"
        to="/library"
      >
        <img
          src="@/assets/student-page-assets/library-books-icon.svg"
          class="library-icon"
          alt=""
        />
        <span class="library-text"> Library</span>
      </router-link>

      <router-link
        @click="toggle = false"
        class="assigment"
        tag="p"
        :to="`/my-educlass-${userInfo.role.toLowerCase()}/assigments`"
      >
        <img
          src="@/assets/student-page-assets/assigment_icon.svg"
          class="assigment-icon"
          alt="assigment"
        />
        <span class="assigment-text">Assignments</span>
      </router-link>

      <router-link
        @click="toggle = false"
        tag="p"
        to="/my-educlass-teacher/report"
        class="report"
        v-if="userInfo.role.toLowerCase() != 'student'"
      >
        <img
          src="@/assets/student-page-assets/source_icon.svg"
          class="report-icon"
          alt=""
        />
        <span class="report-text"> Reports</span>
      </router-link>
      <RLP :isShownType="'header-page'" @setToggle="toggle = false" />
      <MEQ :isShownType="'header-page'" @setToggle="toggle = false" />

 

    </div>
    <div
      class="logged-user-container"
      :style="toggle ? { visibility: 'visible' } : {}"
    >
      <div class="logged-user" :style="toggle ? { visibility: 'visible' } : {}">
        <div class="info-user">
          <div class="user-role">{{ userInfo.role }}</div>
          <div class="user-school">{{ userInfo.instituteName }}</div>
          <div class="user-campus">
            {{ userInfo.campusName }}
            <span v-if="!userInfo.className && userInfo.role == 'student'">
              / {{ userInfo.className }}
            </span>
          </div>
        </div>
        <div class="image-user">
          {{
            userInfo.firstName[0].toUpperCase() +
            userInfo.lastName[0].toUpperCase()
          }}
        </div>

        <img
          src="@/assets/student-page-assets/logout_icon.svg"
          @click="setLogOut"
          class="logout-icon"
          alt=""
        />
      </div>
    </div>

    <i @click="toggle = !toggle" class="fa-solid fa-bars"  :style="toggle ?  {'top':'9%'} : {'align-self':'center'} "></i>
  </div>

</div>
</template>

<script>
  import RLP from '@/components/RLP/Index.vue'
  import MEQ from '@/components/MEQ/Index.vue'
export default {
  components:{
    RLP,
    MEQ
  },
  data() {
    return {
      userInfo: null,
      toggle: false,
      responsiveStyle: {
        position: "fixed",
        "z-index": "99",
        inset: "0",
        height: "100vh",
        overflow: "hidden",
        "background-image":
          "linear-gradient(rgb(223, 219, 219),rgba(236, 236, 16, 0.971))",
      },
    };
  },


    computed:{
      isResourcesFull(){
        if(this.$store.getters["teacher/getGroupedResorces"].length) return true;
        return false;
      }
    },

  methods: {
  setHeadBarView(e) {
 
    if(e.target.innerWidth > 930) this.toggle = false;
    
  },
  setLogOut(){
    sessionStorage.removeItem('userInfos');
    
    this.$router.push('/')

    this.$store.commit('resetUserDetail');
     
    
  }
},
created() {
  window.addEventListener("resize", this.setHeadBarView);
},
unmounted() {
  window.removeEventListener("resize", this.setHeadBarView);
},

  beforeMount() {
    let {
      organization,
      first_name: firstName,
      last_name: lastName,
      role_type: role,
    } = JSON.parse(sessionStorage.getItem("userInfos"));
    let className = "";
    let campusName = "";
    let instituteName = "";
    organization.forEach((item) => {
      if (item.type == "grade") className = item.name;
      if (item.type == "campus") campusName = item.name;
      if (item.type == "main") instituteName = item.name;
    });

    this.userInfo = {
      className,
      campusName,
      instituteName,
      firstName,
      lastName,
      role,
    };
  },
};
</script>

<style scoped lang="scss">
.header-container {
  display: flex;
  flex-direction: row wrap;
  justify-content: flex-start;
  align-items: baseline;
 

  position: relative;
//   left: 0;
  height: 84px;
  margin: auto;
  top: 0;
  width: 80%;
  padding: 0;

  @media (min-width: 1386px) {
    width:81%;
   
    }


  @media (max-width: 1386px) {
    width: 84%;
    }

    @media (max-width: 1250px) {
        width: 91%;
    }

    @media (max-width: 1200px) {
        width: 93%;
      
    }
    @media (max-width: 1050px) {
        width: 96%;
    }


    @media (max-width: 930px) {
        width: 100%;
      
    }

 

}

.fa-bars {
  color: rgb(13, 13, 46);
  font-size: 1.4rem;
  position: absolute;

  right: 3%;
  visibility: hidden;
  cursor: pointer;

  &:hover {
    color: rgb(75, 75, 192);
  }

  @media (max-width: 930px) {
    visibility: visible;
    font-size: 1.5rem;
    display: flex;
      
  }
}

.logo {
  height: 64px;

  background-repeat: no-repeat;
  cursor: pointer;
  /* Inside auto layout */

  flex-basis: 14%;
  
  @media (min-width:1380px) {
    align-self:baseline !important;
  }

  @media (max-width: 930px) {
    flex-basis: auto;
    position: absolute;
    left: 5%;
      display: flex;
    
      
    margin: 0;

    height: 54px;
  }



}

.navbar-item {
  flex-basis: 62%;

  
  display: flex;
  flex-direction: row;
  justify-content: center;

 
  gap: 6%;
  height: 20px;

  @media (min-width: 1400px) {
        padding-left: 10%;
       
  }

  @media (min-width: 1368px) {
        padding-left: 2%;
       
  }
    

  @media (max-width: 1200px) {
        flex-basis: 60%;
       
    }

    @media (max-width: 1050px) {
        flex-basis: 58%;
       
    }

  @media (max-width: 930px) {
    flex-basis: 100%;
    position: absolute;
    top: 50vh;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;

    height: 50vh;

    gap: 0;
    visibility: hidden;

    // flex-direction: column;
  }

  @media (max-width: 500px) {
    top: 45vh;
  }

  // @media (max-width: 620px) {

  //  left: 16%;

  // }

  // @media (max-width: 480px) {

  //  left: 2%;

  // }

  /* Inside auto layout */

  .resources {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 6px;

    color: #712581;
    min-width: 98px;
    height: 20px;

    /* Inside auto layout */

    text-decoration-line: none;

    @media (max-width: 930px) {
      background: rgb(39, 26, 77);
      display: flex;
      padding-left: 80%;

      width: 100%;
      height: 75px;
      color: #eae5eb !important;
      border-bottom: 1px solid #eae5ebad !important;

      .resources-icon {
      }
      cursor: pointer;
      &:hover {
        background: rgb(81, 63, 134);
      }
    }

    .resources-icon {
      width: 16px;
      height: 16px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .resources-text {
      width: 76px;
      height: 20px;

      font-family: "Source Serif Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height */

      /* Secondary */

      /* Inside auto layout */

      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }

  .library {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 6px;
    color: #712581;
    width: 78px;
    height: 20px;
    text-decoration-line: none;

    flex: none;
    order: 1;
    flex-grow: 0;
    @media (max-width: 930px) {
      background: rgb(39, 26, 77);
      display: flex;
      padding-left: 80%;
      width: 100%;
      height: 75px;
      color: #eae5eb !important;
      border-bottom: 1px solid #eae5ebad !important;
      cursor: pointer;
      &:hover {
        background: rgb(81, 63, 134);
      }
    }

    .library-icon {
      width: 16px;
      height: 16px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .library-text {
      width: 56px;
      height: 20px;

      font-family: "Source Serif Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;

      /* identical to box height */

      /* Secondary */

      /* Inside auto layout */

      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }

  .assigment {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 6px;
    color: #712581;
    width: 117px;
    height: 20px;

    text-decoration-line: none;
    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;

    @media (max-width: 930px) {
      background: rgb(39, 26, 77);
      display: flex;
      padding-left: 80%;
      width: 100%;
      height: 75px;
      color: #eae5eb !important;
      border-bottom: 1px solid #eae5ebad !important;
      cursor: pointer;
      &:hover {
        background: rgb(81, 63, 134);
      }
    }

    .assigment-icon {
      width: 16px;
      height: 16px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .assigment-text {
      width: 95px;
      height: 20px;

      font-family: "Source Serif Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height */

      /* Inside auto layout */

      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }

  .report {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 6px;
    color: #712581;
    width: 98px;
    height: 20px;

    /* Inside auto layout */

    flex: none;
    order: 3;
    flex-grow: 0;
    text-decoration-line: none;

    @media (max-width: 930px) {
      background: rgb(39, 26, 77);
      display: flex;
      padding-left: 80%;
      width: 100%;
      height: 75px;
      color: #eae5eb !important;
      border-bottom: 1px solid #eae5ebad !important;
      cursor: pointer;
      &:hover {
        background: rgb(81, 63, 134);
      }
    }

    .report-icon {
      width: 16px;
      height: 16px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .report-text {
      width: 76px;
      height: 20px;

      font-family: "Source Serif Pro";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height */

      /* Inside auto layout */

      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }
}

.logged-user-container {
  height: 64px;
  position: relative;
  /* Inside auto layout */
  flex-basis: 15%;

  
  @media (min-width: 1386px) {
  
    position: relative;
    display: flex;
   
    align-self: center;
    flex-basis: 25%;

  }
  @media (max-width: 930px) {
    position: absolute;
    top: 20vh;
    // left: 38%;
    visibility: hidden;
    width: 100vw;
    height: 30vh;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255) !important;
    z-index: 9999;
    
  }

  @media (max-width: 500px) {
    top: 15vh;
  }


}
.logged-user {
  height: 64px;
  position: relative;
  /* Inside auto layout */
  flex-basis: 35%;
 
  @media (min-width: 1386px) {
    flex-basis: 100%;
    display: flex;
    justify-content: flex-end;
    
    z-index: 9999;
 
  }

  @media (max-width: 930px) {
    flex-basis: 40%;

    display: flex;
    height: 50%;
    align-self: center;
    justify-content: center;
    justify-items: center;
    margin: auto;
  }

  @media (max-width: 390px) {
    flex-basis: 70%;
  }



  // @media (max-width: 620px) {

  //     margin-right: 20%;

  // }

  // @media (max-width: 480px) {

  //     margin-right: 20%;

  // }

  .info-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0px;

    position: absolute;
    top: 8px;
    left: 0;
    width: 141px;
    height: 57px;
    gap: 5px;

    @media (min-width: 1386px) {
    flex-basis: 42%;
    position: relative;
    align-items: flex-end;
    margin-right: 8%;
   
    
    inset: 0;
 
 
  }
    .user-role {
      width: auto;
      height: 19px;

      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      /* identical to box height */

      text-align: right;

      /* Black */

      color: #0f0f0f;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .user-school {
      width: auto;
      height: 19px;

      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      /* identical to box height */

      text-align: right;

      /* Secondary */

      color: #712581;

      /* Inside auto layout */

      flex: none;
      order: 1;
      flex-grow: 0;
    }

    .user-campus {
      width: auto;
      height: 19px;

      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      /* identical to box height */

      text-align: right;

      /* Secondary */

      color: #712581;

      /* Inside auto layout */

      flex: none;
      order: 2;
      flex-grow: 0;
    }
  }

  & .image-user {
    box-sizing: border-box;

    position: absolute;
    width: 64px;
    height: 64px;
    left: 157px;
    top: 0px;
    border-radius: 50%;
    background: #fff;
    color: rgb(24, 24, 73);
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid rgba(38, 38, 175, 0.205);
    
   
    


    @media (min-width: 1386px) {
    flex-basis: 22%;
    margin-right: 4%;
  
   
 
  
    position: relative;
    inset: 0;
    z-index: -9;

 
 
  }
  }

  & .logout-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 237px;
    top: 20px;
    cursor: pointer;
    background: rgba(113, 37, 129, 0.2);
    border-radius: 4px;

    &:hover {
      background: rgba(174, 111, 187, 0.986);
    }


    @media (min-width: 1386px) {
        position: relative;
        display: flex;
       
        inset: 0;
        align-self: center;

    flex-basis: 10%;
 
 
  }
  }
}
</style>