import {createStore} from "vuex";
import student from "./modules/student"
import teacher from "./modules/teacher";
import auth from "./modules/auth";
import user from './modules/user';
import content from './modules/content';
import googleClassroom from './modules/google-classroom';
import createPersistedState from 'vuex-persistedstate';
import contentServices  from '@/services/content'

export default createStore({
    plugins: [createPersistedState()],
    modules:{
        student,
        teacher,
        auth,
        user,
        content,
        googleClassroom
    },
    state:{

        library:[],
        isSpinnerActive:false,
        userSchoolDefaultId:0
    },

    mutations:{

        setLibrary(state,library){
            state.library = library ;
        },

        setSpinner(state,status){
          
          state.isSpinnerActive = status; 
        },

        setUserSchoolId(state,id){
          state.userSchoolDefaultId = id ;
        }
    },
    actions:{
        async setLibrary({commit,getters}){

          

             let grade,gradeReady,libraryItems = null ;
            
             const school_id =  getters.userDetails.organization.find(x => x.type === 'main').id;
             await  getters.userDetails.organization.filter(y => y.type === "grade").forEach(element => {
               let length = [66283].filter(x => x === element.id).length;
               if(length > 0){
                  gradeReady = true;
               }
             });
             if(school_id === 48710 || school_id === 3432 || school_id === 66238 || gradeReady === true){ //Piri Reis Üniversitesi & Okyanus Koleji & Yıldız Koleji 99/A
               const res = await contentServices.getPiriScorms(1);
               
               libraryItems = res.data;
             }else if(school_id === 3432 && grade === 6){
          
               const res = await contentServices.getPiriScorms(1);
               
               libraryItems = res.data;
             }
             else{
               const res = await contentServices.getLibraryScorms(0, 1, 'reader');
               
               libraryItems = res.data;
             }


             commit('setLibrary',libraryItems);


            
        }
    },

    getters:{
            getLibrary(state){
                return state.library ;
            },
            getSpinnerStatus(status){
              return status.isSpinnerActive ;
            },

            getIsUserSchoolIdRestrict(state){
             return [11614].includes(state.userSchoolDefaultId);
            }
    }
})