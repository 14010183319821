<template>
  <div class="footer-container">
    <img
      src="@/assets/footer-assets/logo_white.svg"
      class="logo"
      alt="logo_white"
    />
  </div>
</template>



<style scoped lang="scss">
.footer-container {
  position:relative;
  height: 60px;
  width: 100%;
  inset: 0;
// padding-top: 50px;
  background: #712581;
}

.logo {
  position: absolute;
  width: 72px;
  height: 44px;
  left: calc(50% - 72px / 2);
  top: calc(50% - 44px / 2);
}
</style>