export default function(assignments){

    let groupAssigmentsName = [];
    let assignmentGroupObject = [];
    let finalFilteredAndGroupedAssignments = [];

    if (assignments.length) {

        assignments.forEach((item) => {

            groupAssigmentsName.push(item.assignment_name)

        })

        let filteredGroupAssigment = new Set(groupAssigmentsName);


        filteredGroupAssigment.forEach(name => {

            assignmentGroupObject.push({ name });
        })

        let instanceAssignmentGroupObject = assignmentGroupObject;

        assignments.forEach((item) => {

            assignmentGroupObject.forEach((nameReferance, index) => {
                if (nameReferance.name == item.assignment_name) {

                    if (nameReferance.hasOwnProperty(item.name)) {

                        instanceAssignmentGroupObject[index][item.name].push(item);

                    }

                    else {

                        instanceAssignmentGroupObject[index][item.name] = [];
                        instanceAssignmentGroupObject[index][item.name].push(item);

                    }


                }
            })

        })
}


assignmentGroupObject.forEach(item=>{
    for (const key in item) {
        if(key != 'name'){
            finalFilteredAndGroupedAssignments.push(item[key])
        }
       
    }
})

return finalFilteredAndGroupedAssignments;
}