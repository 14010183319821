<template>
  <div v-show="nlTawk">
    <a href="https://tawk.to/chat/60754be5067c2605c0c1dcbc/1f3530unq" target="_blank">
        <div :class="$style.divTawk">
            <!-- <img src="/static/img/chat.png" alt="" width="30" height="30" :class="$style.divTawkIcon"> -->
            <svg xmlns=http://www.w3.org/2000/svg fill="#fff" viewBox="0 0 800 800" height="100%" width="50%"
            role="img" alt="Chat icon" class="tawk-min-chat-icon" :class="$style.divTawkIcon">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M400 26.2c-193.3 0-350 156.7-350 350 0 136.2 77.9 254.3 191.5
                312.1 15.4 8.1 31.4 15.1 48.1 20.8l-16.5 63.5c-2 7.8 5.4 14.7 13
                12.1l229.8-77.6c14.6-5.3 28.8-11.6 42.4-18.7C672 630.6 750 512.5 750
                376.2c0-193.3-156.7-350-350-350zm211.1 510.7c-10.8 26.5-41.9 77.2-121.5
                77.2-79.9 0-110.9-51-121.6-77.4-2.8-6.8 5-13.4 13.8-11.8 76.2 13.7 147.7 13 215.3.3 8.9-1.8 16.8 4.8 14 11.7z">
            </path>
            </svg>
        </div>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import serviceAuth from '@/services/auth';

export default {
    data() {
      return {
        nlTawk: false,
      };
    },
    async beforeMount(){
       
        const nlTawkTime = setInterval( async () => { // ! App.vue nin içerisinde olduğu için userDetails'i çekene kadar tekrar ediyoruz.
            if(this.userDetails != null){
                const res = await serviceAuth.checkSchool();
                
                const schoolFilter = res.data.organizations.filter(y => y === this.userDetails.organization.find(x => x.type === 'main').id);
                
                if (schoolFilter.length > 0) { // todo Eğer Hollanda Okuluysa Türkçeyi gizle ve Manuel Tawk Butonunu Göster
                // window.go_nlTawk();
                setTimeout(() => {
                    window.Tawk_API.hideWidget();
                    this.nlTawk = true;
                    }, 2000);
                }
                
                

                clearInterval(nlTawkTime);
            } 
        }, 1000);

        // this.nlTawk = true;
    },
    computed:{
        ...mapGetters(['userDetails']),
    }
}
</script>
<style src='./style.less' lang='less' module/>
<style>

</style>