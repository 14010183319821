<template>
  <div class="warning-container">
    <p v-if="isFetched" style="textalign: center">
      <img
        style="height: 75px; width: 75px"
        :src="require('@/assets/ring-spinner.svg')"
        alt=""
      />
    </p>

    <p class="text" v-else>There is no {{ messageObject }} !</p>
  </div>
</template>

<script>
export default {
  props: {
    messageObject: {
      default: "item",
    },
  },

  data() {
    return {
      isFetched: true,
    };
  },

  mounted() {
   
    let loadingTime = this.$route.fullPath.split('/').pop() == 'teacher-scorms' ? 3000 : 200 ;
    
    setTimeout(() => {
      this.isFetched = false;
    }, loadingTime);
  },
};
</script>

<style scoped lang="scss">
.warning-container {
  width: 90%;
  min-height: 50vh;
  height: auto;
  margin: 50px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(247, 247, 247, 0.623);
}

.text {
  color: rgb(233, 14, 14);
  font-size: 1.1rem;
  font-weight: 600;
  text-align: justify;
}
</style>