
import filterAssignments from '@/helper_functions/filterAndGroupAssigments';
import axios from '@/services';
import services from '@/services/content'
import moment from 'moment'


export default {

    namespaced: true,
    state: {
        selectedAssigneeType:sessionStorage.getItem('assignmentType') || 'individual',
        selectedAssigments: [],
        selectedScormGroup:null,
        teacherGroupAssigments: [],
        teacherIndividualAssigments: [],
        groupedResources: [],
        resources: [],
        subResources: [],
        teacherScorms: [],
        selectedEditIds:[],
        assigmentOptionIsShow:true,
        assigmentScormResultDetail:[],
        createSectionSelectedScorms:[],
        assigmentNewCreateDetail:{
            assignment_desc:'',
            assignment_end_date:'',
            assignment_name:'',
            assignment_scorms:[],
            assignment_start_date:'',
            assignment_type:'',
            block_after_due_date:false,
            ids:[],
            single_access:false,
            status:true
        }
        
    },

    mutations: {
        setChoosenAssigments(state, assigments) {
         

            if(assigments == 'groupAssigments') state.selectedAssigments = state.teacherGroupAssigments.map(({assignment_id})=>{
                return assignment_id
            }) 
            else if(assigments == 'individualAssigments') state.selectedAssigments = state.teacherIndividualAssigments.map(({assignment_id})=>{
                return assignment_id
            }) 
           
            else if(Object.keys(assigments).length && typeof(assigments) == 'object'){
               
                if(assigments.isChecked){

                    assigments.assignmentsAll.forEach(id=>state.selectedAssigments.push(id));
             
            }
            else {
                state.selectedAssigments = state.selectedAssigments.filter(item=>{


                    return !(assigments.assignmentsAll.includes(item));

                });
            }

                
            } 
            else state.selectedAssigments = [];
            
           
        },

        setAssigmentScormResultDetail(state,scormDetail){
          
            state.assigmentScormResultDetail = scormDetail;
        },

        setSelectedScormGroup(state,item){
            
            state.selectedScormGroup = item ;
        },

        setResources(state, resources) {
            state.resources = resources;
            let resourcesParent = resources.filter(collec => {
                if (collec.parent == 0) return collec;
            })

            state.groupedResources = resourcesParent;
        },

        setTeacherIndividualAssigment(state, assigment) {

            state.teacherIndividualAssigments = assigment;
        },

        setTeacherGroupAssigment(state, assigment) {

            state.teacherGroupAssigments = assigment;
        },

        setSubResources(state, collection) {
               
            state.subResources = state.resources.filter(collect => {
                if (collect.parent != collection.parent && collect.parent == collection.id) return collect;
            })

        },

        setTeacherScorms(state, scorms) {
            
            state.teacherScorms = scorms;
        },
        setSelectedEditIds(state,editIds){
                state.selectedEditIds = editIds;
        },
        setAssignmentIsShow(state){
            state.assigmentOptionIsShow = !state.assigmentOptionIsShow;
        },
        setSelectedAssigneeType(state,type){
            state.selectedAssigneeType = type;
        },

        setAssigmentDetail(state,{name,value}){
            state.assigmentNewCreateDetail[name] = value;
        },

        setSelectedCreateSectionScorms(state,scorms){
            state.createSectionSelectedScorms =scorms;
        },

        setAssignmentFilter(state,{assigneeType = 'individual',startDate = 0, endDate = 0,assignee=[],assigmentsAll}){
               
         
                if(assignee.length){
                    if(assigneeType == 'group'){
                        state.teacherGroupAssigments = assigmentsAll.filter(assignment=>{
                            let dateAssignmentStart = moment(assignment.start_date).valueOf();
                            let dateAssignmentEnd = moment(assignment.end_date).valueOf(); 
                             if(!(assignee.length) && (!(startDate) && !(endDate))){
                                return   dateAssignmentStart <= startDate && dateAssignmentEnd >= endDate ;
                            }
                            else if((assignee.length) && (startDate == null && endDate == null)){
                                return assignee.includes(assignment.name)
                            }
                            else if( (assignee.length) && startDate != null && endDate == null){
                                return assignee.includes(assignment.name) &&  dateAssignmentStart <= startDate
                            }
                            else if( (assignee.length) && endDate != null && startDate == null){
                                return assignee.includes(assignment.name) && dateAssignmentEnd >= endDate
                            }
                          
                        
                            return assignee.includes(assignment.name) &&  dateAssignmentStart <= startDate && dateAssignmentEnd >= endDate ;
                        })

                       
                    }
                    else {
                        state.teacherIndividualAssigments = assigmentsAll.filter(assignment=>{
                            
                            let dateAssignmentStart = moment(assignment.start_date).valueOf();
                            let dateAssignmentEnd = moment(assignment.end_date).valueOf();                          
                            if(!(assignee.length) && (!(startDate) && !(endDate))){
                                return   dateAssignmentStart <= startDate && dateAssignmentEnd >=  endDate ;
                            }
                            else if((assignee.length) && (startDate == null && endDate == null)){
                                return assignee.includes(assignment.name)
                            }
                            else if( (assignee.length) && startDate != null && endDate == null){
                                return assignee.includes(assignment.name) &&  dateAssignmentStart <= startDate
                            }
                            else if( (assignee.length) && endDate != null && startDate == null){
                                return assignee.includes(assignment.name) && dateAssignmentEnd >=  endDate
                            }
                          
                           
                            return assignee.includes(assignment.name) &&  dateAssignmentStart <= startDate && dateAssignmentEnd >= endDate ;
                        });

                       
                    }
                }

                   

        },

     resetAssignmentFilter(state,{assigneeType,assignments}){
 
        if(assigneeType == 'group') state.teacherGroupAssigments = assignments;
        else state.teacherIndividualAssigments = assignments ;
        
     }
     ,

        deleteAssignment(state,assigments){
            let assignmentIds = assigments;
           
                state.teacherIndividualAssigments = state.teacherIndividualAssigments.filter(item=>{
                    return !(assignmentIds.includes(item.assignment_id))
                })
            

           
                state.teacherGroupAssigments = state.teacherGroupAssigments.filter(item=>{
                    return !(assignmentIds.includes(item.assignment_id))
                })
            
        },

        resetAssigmentDetail(state){
            state.assigmentNewCreateDetail = {
                assignment_desc:'',
                assignment_end_date:'',
                assignment_name:'',
                assignment_scorms:[],
                assignment_start_date:'',
                assignment_type:'',
                block_after_due_date:false,
                ids:[],
                single_access:false,
                status:true
            }
        }
    },

    actions: {
        async createAssignment({getters}){
           
            let res;
            const assignmentData = getters['getNewAssigmentDetail'];

           
            const { assignedByGroup, assignedByIndividual,assignment_start_date,assignment_name,assignment_scorms } = assignmentData;
            
            
            if(!(assignedByGroup?.length || assignedByIndividual?.length) && !assignment_start_date && !assignment_name && !assignment_scorms?.length){
                return false;
            }

           
            
            if(assignedByGroup?.length){
               
                assignmentData.ids = assignedByGroup;
               assignmentData.assignment_type = 'group';
               
               res = await axios().post('/assignment/create',assignmentData);
               
              
            }
            if(assignedByIndividual?.length){
                assignmentData.ids = assignedByIndividual
                assignmentData.assignment_type = 'individual';
               
                res = await axios().post('/assignment/create',assignmentData);
                
            }
           
           
            
           return res;
            
           
        },


        async getResources({ commit }) {
            let { data: { data } } = await axios().post('collections');
           
            commit('setResources', data)
               
            return data;

        },

        async getTeacherScorms({ commit }, collection) {

               
                try {
                    let path = `/scorms/`
                   
                    let { data:{pagination:{last_page}} } = await axios().post(path,{collection_id:collection.id});
                    
                        
                    let scormContainer = [];
                    let i = 1;
                    while(i<=last_page){
                        let path = `/scorms?page=${i}`
                   
                    let { data: { data } } = await axios().post(path,{collection_id:collection.id});
                    scormContainer.push(...data)
                        i++;
                    }
                    
                    
                   
                    
                    commit('setTeacherScorms', scormContainer);
                    return true
                } catch (error) {
                    return error;
                }
           
        },

        async getTeacherAssigments({ commit }) {
            try {
                let { data: { data: groupAssigments } } = await services.getAssignments('1', 'id');
                let { data: { data: individualAssigments } } = await services.getAssignments('1', 'id', 'individual');
                commit('setTeacherGroupAssigment', groupAssigments);
                commit('setTeacherIndividualAssigment', individualAssigments);

                return true;
            } catch (error) {
               
               
                return error
            }
           
        },

        async deleteAssignment({commit,getters}){
            let assignments = getters['getSelectedAssigments'];
            let res;
            for(const item of assignments){

                try {
                    let path = `/assignment/${item}/deleteassignment`
                    res = await axios().post(path);
                } catch (error) {
                    alert(error);
                   res = false;
                   
                }
              
            }
            if(res){
                commit('deleteAssignment',assignments);
                return true;
            }

            return false;
          
            
        },

      
    },

    getters: {
        getNewAssigmentDetail(state){
            return state.assigmentNewCreateDetail;
        },
        getSelectedAssigments(state) {
            return state.selectedAssigments;
        },

        getSelectedEditIds(state){
            return state.selectedEditIds;
        },

        getAssignmentOptionIsShow(state){
            return state.assigmentOptionIsShow;
        },
        getSelectedCreateSectionScorms(state){
            return state.createSectionSelectedScorms
        },



        getResources(state) {
            return state.resources;
        },

        getSelectedScormGroup(state){
            
            return state.selectedScormGroup;
        },

        getGroupedResorces(state) {
            return state.groupedResources;
        },

        getSubResources(state) {
            return state.subResources;
        },

        getTeacherScorms(state) {
            return state.teacherScorms;
        },

        getSelectedAssigneeType(state){
            return state.selectedAssigneeType;
        },


        getTeacherIndividualAssigment(state) {

          
            return filterAssignments([...state.teacherIndividualAssigments]);
            
        },

        getTeacherGroupAssigment(state) {
        

                return filterAssignments([...state.teacherGroupAssigments]);
            },


            getSelectedScormResult(state){

    if( Object.keys(state.assigmentScormResultDetail).length !== 0 ){

    let currentDate = new Date();
    let assigmentExpiredDate = new Date(state.assigmentScormResultDetail.assignment_end_date);


currentDate.getTime() > assigmentExpiredDate.getTime() ? state.assigmentScormResultDetail.status = 'expired' :state.assigmentScormResultDetail.status = 'Ongoing'

return state.assigmentScormResultDetail ;
                }

                else {
                 return   state.assigmentScormResultDetail;

                }


                
            }

        

    }
}