import { createRouter, createWebHashHistory } from 'vue-router'
import tools from '../tools';


const routes = [

  {
    path: '/',
    name: 'login',
    component: ()=> import("../components/Login/Index.vue"),
    props:true
  },

  {
    path:'/library',
    name:"library-page",
    component: ()=> import("../views/Library.vue"),
    props:true,

  },

  {
    path:'/platforms',
    name:"platforms-page",
    component: ()=> import("../views/Platforms.vue"),
    props:true,

  },

  {
    path: '/scorm/:id/:packageType?/:packageId?/:scormCode/:scormTitle/:scormDesc?',
    name: 'scorm',
    component: ()=> import("../views/Scorm.vue"),
    props: true,
  },

  {
    path: '/my-educlass-student',
    name: 'my-educlass-student',
    component: ()=> import("../views/MyEduClassStudent/Index.vue"),
    props:true,
    children:[
      {
        path:'',
        name:"student-entry-page",
        component: ()=> import("../views/MyEduClassStudent/StudentEntryPage.vue"),
      },
    
      {
        path:'assigments',
        name:"student-assigments",
        component: ()=> import("../views/MyEduClassStudent/StudentAllAssignments.vue"),
        props:true,

      },

      {
        path:'assigment-detail',
        name:"student-assigment-detail",
        component: ()=> import("../views/MyEduClassStudent/StudentAssigmentDetail.vue"),
        props:true,

      },

    ]
  },
  {
    path: '/my-educlass-teacher',
    name: 'my-educlass-teacher',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MyEduClassTeacher/Index.vue'),
    // beforeEnter: (to, from, next) => {
    //   if (JSON.stringify(sessionStorage.getItem('role')) !== 'teacher') {
    //     next({
    //       name: 'login',
    //     });
    //   }
    //   next();
    // },
    props:true,
    children:[
   
      {
        path:'',
        name:"teacher-entry-page",
        component: ()=> import("../views/MyEduClassTeacher/TeacherEntryPage.vue"),
      },

      {
        path:'report',
        name:"teacher-report-page",
        component: ()=> import("../views/MyEduClassTeacher/TeacherAssignmentsReport.vue"),
      },

      {
        path:'resources',
        name:"teacher-resource-page",
        component: ()=> import("../views/MyEduClassTeacher/TeacherResource.vue"),
        props:true,

      },

      {
        path:'sub-resources',
        name:"teacher-sub-resource-page",
        component: ()=> import("../views/MyEduClassTeacher/TeacherSubResources.vue"),
        props:true,

      },

      {
        path:'teacher-scorms',
        name:"teacher-scorms-page",
        component: ()=> import("../views/MyEduClassTeacher/TeacherScorms.vue"),
        props:true,

      },

      {
        path:'teacher-scorms-detail-result',
        name:"teacher-scorms-detail-result",
        component: ()=> import("../views/MyEduClassTeacher/TeacherScormResultDetail.vue"),
        props:true,

      },




      {
        path:'resources-list',
        name:"teacher-resource-list-page",
        component: ()=> import("../views/MyEduClassTeacher/TeacherResourcesList.vue"),
        props:true,
        
      },

      {
        path:'/my-educlass-teacher/assigments',
        name:"teacher-assigments",
        component: ()=> import("../views/MyEduClassTeacher/TeacherAssigments.vue"),
        props:true,
        children:[
          {
            path:'remove-assigment',
            name:"teacher-remove-assigment",
            component: ()=> import("../components/MyEduClassTeacher/teacher_assigments/RemoveAssigment.vue"),
            props:true,
            
          },
          {
            path:'/my-educlass-teacher/assigments/create-assigment',
            name:"teacher-create-assigment",
            component: ()=> import("../components/MyEduClassTeacher/teacher_assigments/create_assigment/Index.vue"),
            props:true,

            children:[

              {
                path:'',
                name:"create-details",
                component: ()=> import("../components/MyEduClassTeacher/teacher_assigments/create_assigment/CreateDetails.vue"),
               
                
              },

              {
                path:'create-resources',
                name:"create-resources",
                component: ()=> import("../components/MyEduClassTeacher/teacher_assigments/create_assigment/CreateResources.vue"),
               
                
              },

              {
                path:'create-assignee',
                name:"create-assignee",
                component: ()=> import("../components/MyEduClassTeacher/teacher_assigments/create_assigment/CreateAssignee.vue"),
               
                
              },

              {
                path:'create-options',
                name:"create-options",
                component: ()=> import("../components/MyEduClassTeacher/teacher_assigments/create_assigment/CreateOptions.vue"),
               
                
              },

           

            ]
            
          },

          {
            path:'edit-assigment',
            name:"teacher-edit-assigment",
            component: ()=> import("../components/MyEduClassTeacher/teacher_assigments/EditAssigment.vue"),
            props:true,
            
          },
          {
            path:'filter-assigment',
            name:"teacher-filter-assigment",
            component: ()=> import("../components/MyEduClassTeacher/teacher_assigments/FilterAssigment.vue"),
            props:true,
            
          },
        ]
      }

  


    ]
  },

  {
    path: '/:catchAll(.*)*',
    name: "PageNotFound",
    component: ()=> import('@/components/main-components/HeaderApp.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.afterEach((to) => {
  if (to.name !== 'login') {
    if (!tools.cookie.get('access_token')) {
      
      router.push({ name: 'login' });
    }
  }

});


export default router
